import Input from '@/interfaces/inputs/Input'
import FormElement from '@/interfaces/form/FormElement'
import Checkbox from '@/interfaces/inputs/Checkbox'
import Radio from '@/interfaces/inputs/Radio'
import RadioGroup from '@/interfaces/inputs/RadioGroup'

const showPhoto: Checkbox = {
  id: 'showPhoto',
  label: 'Mostrar imagen de perfil',
  type: 'checkbox',
  value: false
}

const title: Input = {
  id: 'title',
  label: 'Titulo',
  type: 'text',
  value: null,
  required: true
}

const subTitle: Input = {
  id: 'subTitle',
  label: 'Subtitulo',
  type: 'text',
  value: null,
  required: true
}

const backgroundImage: Input = {
  id: 'backgroundImage',
  label: 'Imagen de fondo',
  type: 'file',
  value: null
}

const social: Checkbox = {
  id: 'social',
  label: 'Mostrar redes sociales',
  type: 'checkbox',
  value: false
}

const dog: Radio = {
  id: 'dog',
  label: 'Dog',
  type: 'radio',
  value: 'dog'
}

const cat: Radio = {
  id: 'cat',
  label: 'Cat',
  type: 'radio',
  value: 'cat'
}

const pets: RadioGroup = {
  id: 'pets',
  label: 'Mascota',
  name: 'pets',
  value: '',
  radios: [dog, cat]
}

/*const linkedin: Input = {
  id: 'linkedin',
  label: 'Linkedin',
  type: 'text',
  value: null
}*/

export const formElements: FormElement[] = [
  {
    type: 'input',
    data: backgroundImage
  },
  {
    type: 'input',
    data: showPhoto
  },
  {
    type: 'input',
    data: title
  },
  {
    type: 'input',
    data: subTitle
  },
  {
    type: 'input',
    data: social
  },
  {
    type: 'radioGroup',
    data: pets
  }
  /*{
    type: 'input',
    data: linkedin
   isVisible: (formElements: FormElement[]) => {
      return (
        formElements.find(element => element.data.id === 'social').data
          .value === true
      )
    }
  }*/
]
